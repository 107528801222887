<template>
  <div>
    <b-modal
      ref="modal-upload-file-drive"
      title="Upload Drive"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @hidden="hiddenModal"
      size="md"
    >
      <b-form>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-form-group
              label=""
              label-for="contact-options"
              label-class="mb-1">
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="uploadData.isCreateNewFolder"
                  class="custom-control-primary">
                  {{ $t('Create New Folder')}}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="12" v-if="uploadData.isCreateNewFolder">
            <b-form-group :label="$t('New Folder Name')" label-for="uploadData.folderName">
              <b-form-input id="uploadData.folderName" v-model="uploadData.folderName"/>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <b-button variant="primary" class="float-right" @click="handleOk">
          <b-spinner
            v-if="$store.getters.getLoader"
            class="mr-1"
            small
            variant="light"
          />
          <feather-icon
            v-else
            icon="UploadCloudIcon"
            class="mr-50"
          />
          {{ $t("Start Upload") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'
import ApiService from '@/common/api.service'

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      uploadData: {
        isCreateNewFolder: false,
        folderName: null,
      },
    }
  },
  methods: {
    uploadToDrive() {
      ApiService.post('manage/order/file/uploaddrive', this.uploadData, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: this.$t(response.message) },
            });
            this.modalObject.onSuccess();
            this.hiddenModal();
          } else {
            AlertService.error(this, this.$t(response.message))
          }
        })
    },

    initialModal() {
      this.$refs['modal-upload-file-drive'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.uploadToDrive()
    },
    hiddenModal() {
      this.$refs['modal-upload-file-drive'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal();
  },
};
</script>
