import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrdersList(isArchivedParam, orderStatus = null) {
  // Use toast
  const toast = useToast()

  const refOrderListTable = ref(null)
  
  // Table Handlers
  const tableColumns = [
    { key: 'orderNo', sortable: true },
    { key: 'orderDate', sortable: true },
    { key: 'user', sortable: true },
    { key: 'orderStatus', sortable: true },
    { key: 'orderTitle', sortable: true },
    { label: 'Payment', key: 'paymentTypeLabel', sortable: true },
    { key: 'subtotal', sortable: true },
    { label: 'Source', key: 'referenceCode', sortable: true },
    { label: 'Download', key: 'isDownloadedFiles' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalOrders = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(orderStatus)
  const customerFilter = ref(null)
  
  const dataMeta = computed(() => {
    const localItemsCount = refOrderListTable.value ? refOrderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    }
  })
  
  const refetchData = () => {
    refOrderListTable.value.refresh()
  }
  
  watch([currentPage, perPage, searchQuery, statusFilter, customerFilter], () => {
    refetchData()
  })
  
  const fetchOrders = (ctx, callback) => {
    store
      .dispatch('orderManagement/fetchOrders', {
        Search: searchQuery.value,
        PageSize: perPage.value,
        PageIndex: currentPage.value,
        sortBy: sortBy.value,
        isSortDirDesc: isSortDirDesc.value,
        OrderStatus: statusFilter.value,
        userId: customerFilter.value,
        isArchived: isArchivedParam,
        productTypes: [0, 1, 2],
      })
      .then(response => {
        const { data, count } = response.result;
        if (data) {
          data.forEach(item => {
            item.isLoading = false;
            if (item.hasMissedFile) {
              item._rowVariant = 'danger';
            }
          });
        }
        callback(data)
        totalOrders.value = count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching orders list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  
  const resolveStatusVariant = role => {
    if (role === 0) return 'danger'
    if (role === 1) return 'success'
    if (role === 2) return 'info'
    if (role === 3) return 'warning'
    return 'primary'
  }
  
  const resolveStatusIcon = role => {
    if (role === 0) return 'SettingsIcon'
    if (role === 1) return 'UserIcon'
    if (role === 2) return 'Edit2Icon'
    if (role === 3) return 'ServerIcon'
    return 'UserIcon'
  }
  
  return {
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrderListTable,
  
    resolveStatusVariant,
    resolveStatusIcon,
    refetchData,
  
    // Extra Filters
    statusFilter,
    customerFilter,
  }
}
